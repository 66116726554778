<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cropImageDialogSlider"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title
            class="elevation-1"
            style="font-family: poppinsextrabold"
          >
            Category({{ user.name }})
            <v-spacer></v-spacer>
          </v-card-title>
          <div class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" md="12"
                    ><v-img height="300px" :src="mediaURL + user.bannerImage"
                      ><template v-slot:placeholder>
                        <ImageLoader /> </template></v-img
                  ></v-col>
                  <v-col cols="12">
                    <span class="item_title">Description</span>
                    <hr />
                    <br />
                    <v-row>
                      <v-col cols="12" md="12">
                        <span class="item_value">{{ user.description }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-layout
              wrap
              justify-center
              v-if="
                (user.generalDetails &&
                  $route.query.id == '64a4ea541c152d17d98ef0eb') ||
                $route.query.id == '64a4ea9c1c152d17d98ef0ef'
              "
            >
              <v-flex xs12 text-right py-5>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialogGeneral"
                  persistent
                  max-width="800px"
                  :key="dialogGeneral"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="green" v-bind="attrs" v-on="on">
                      Update
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form>
                      <v-card-title>
                        <span class="headline">Updates Details</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="user.generalDetails.title"
                                label="Title"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <span>Description</span>
                            </v-col>
                            <v-col cols="12">
                              <div id="app">
                                <vue-editor
                                  v-model="user.generalDetails.content"
                                ></vue-editor>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <ImageComp
                                :singleImage="user.generalDetails.image"
                                @stepper="winStepper"
                                :heading="'Upload Image'"
                                :componentType="'generalImage'"
                              />
                            </v-col>
                            <v-col cols="12">
                              <div id="app">
                                <vue-editor
                                  v-model="user.generalDetails.bottomContent"
                                ></vue-editor>
                              </div>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="12" v-if="$route.query.id == '64a4ea541c152d17d98ef0eb'">
                              <span>Upload images</span>
                              <v-card class="mx-auto" outlined>
                                <v-layout wrap pa-3>
                                  <v-flex xs12 md12 lg12>
                                    <v-layout wrap>
                                      <v-flex lg1> </v-flex>
                                      <v-flex xs12>
                                        <v-layout wrap justify-center fill-height>
                                          <v-flex xs12 sm6 v-for="(item, i) in user.generalDetails.silderImages" :key="i"
                                            pa-1>
                                            <v-img :src="mediaURL + item">
                                              <template v-slot:placeholder>
                                                <ImageLoader />
                                              </template>
                                              <v-layout wrap>
                                                <v-flex text-right pa-0>
                                                  <v-avatar color="#FF3434" size="15">
                                                    <v-icon color="#FFF" small @click="removePhoto(i)">
                                                      mdi-close
                                                    </v-icon>
                                                  </v-avatar>
                                                </v-flex>
                                              </v-layout>
                                            </v-img>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 sm3 v-for="(item, i) in imageArray" :key="i" pa-1>
                                        <v-img :src="item" height="50px" contain>
                                          <v-layout wrap>
                                            <v-flex text-right pa-0>
                                              <v-avatar color="#FF3434" size="15">
                                                <v-icon color="#FFF" small @click="removeImageArray(i)">
                                                  mdi-close
                                                </v-icon>
                                              </v-avatar>
                                            </v-flex>
                                          </v-layout>
                                        </v-img>
                                      </v-flex>
                                    </v-layout>

                                    <div class="dottedline"></div>
                                    <v-layout wrap pt-1 align-center>
                                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                                        <v-icon size="25px" id="pro_pic" @click="$refs.files.click()">mdi-plus</v-icon>
                                        <span style="color: #828282; font-size: 14px">Upload your Image</span>
                                        <input v-show="false" accept="image/*" id="file" ref="files" type="file"
                                          @change="uploadImages" />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-col> -->
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogGeneral = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="editGeneral()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 md4 pa-2 pr-5>
                <v-img contain :src="mediaURL + user.generalDetails.image"
                  ><template v-slot:placeholder> <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm6 md8>
                <span style="font-family: poppinsbold; font-size: 18px"
                  >General Details</span
                >
                <br />
                <br />
                <span style="font-family: poppinsbold; font-size: 16px">{{
                  user.generalDetails.title
                }}</span>
                <br />
                <span
                  style="font-family: poppinsregular; font-size: 16px"
                  v-html="user.generalDetails.content"
                ></span>
              </v-flex>
              <v-flex xs12 pt-5>
                <span
                  style="font-family: poppinsregular; font-size: 16px"
                  v-html="user.generalDetails.bottomContent"
                ></span>
              </v-flex>
              <!-- <v-layout wrap>
                <v-flex xs12 sm6 md3 pa-2 v-for="(list, i) in user.generalDetails.silderImages" :key="i">
                  <v-layout wrap justify-start>
                    <v-flex>
                      <v-img :src="mediaURL + list"><template v-slot:placeholder>
                          <ImageLoader />
                        </template></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout> -->
              <v-layout
                wrap
                v-if="$route.query.id == '64a4ea541c152d17d98ef0eb'"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 sm6 md4 text-center pt-10>
                    <h2>Gallery</h2></v-flex
                  >
                </v-layout>
                <v-flex xs12 text-right py-5>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogSlider"
                    persistent
                    max-width="800px"
                    :key="dialogSlider"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small dark color="green" v-bind="attrs" v-on="on">
                        Update
                      </v-btn>
                    </template>
                    <v-card>
                      <v-form>
                        <v-card-title>
                          <span class="headline">Update</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                v-if="
                                  $route.query.id == '64a4ea541c152d17d98ef0eb'
                                "
                              >
                                <span>Upload images</span>
                                <v-card class="mx-auto" outlined>
                                  <v-layout wrap pa-3>
                                    <v-flex xs12 md12 lg12>
                                      <v-layout wrap>
                                        <v-flex lg1> </v-flex>
                                        <v-flex xs12>
                                          <v-layout
                                            wrap
                                            justify-center
                                            fill-height
                                          >
                                            <v-flex
                                              xs12
                                              sm6
                                              v-for="(slid, s) in slider"
                                              :key="s"
                                              pa-1
                                            >
                                              <v-img
                                                :src="mediaURL + slid.image"
                                              >
                                                <template v-slot:placeholder>
                                                  <ImageLoader />
                                                </template>
                                                <v-layout wrap>
                                                  <v-flex text-right pa-0>
                                                    <v-avatar
                                                      color="#FF3434"
                                                      size="15"
                                                    >
                                                      <v-icon
                                                        color="#FFF"
                                                        small
                                                        @click="
                                                          removePhotoSlider(
                                                            i,
                                                            slid
                                                          )
                                                        "
                                                      >
                                                        mdi-close
                                                      </v-icon>
                                                    </v-avatar>
                                                  </v-flex>
                                                </v-layout>
                                              </v-img>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex
                                          xs12
                                          sm3
                                          v-for="(item, i) in imageArraySlider"
                                          :key="i"
                                          pa-1
                                        >
                                          <v-img
                                            :src="item"
                                            height="50px"
                                            contain
                                          >
                                            <v-layout wrap>
                                              <v-flex text-right pa-0>
                                                <v-avatar
                                                  color="#FF3434"
                                                  size="15"
                                                >
                                                  <v-icon
                                                    color="#FFF"
                                                    small
                                                    @click="
                                                      removeImageArraySlider(i)
                                                    "
                                                  >
                                                    mdi-close
                                                  </v-icon>
                                                </v-avatar>
                                              </v-flex>
                                            </v-layout>
                                          </v-img>
                                        </v-flex>
                                      </v-layout>

                                      <div class="dottedline"></div>
                                      <v-layout wrap pt-1 align-center>
                                        <v-flex
                                          xs10
                                          md6
                                          lg12
                                          align-self-center
                                          text-center
                                          pb-2
                                        >
                                          <v-icon
                                            size="25px"
                                            id="pro_pic"
                                            @click="$refs.files.click()"
                                            >mdi-plus</v-icon
                                          >
                                          <span
                                            style="
                                              color: #828282;
                                              font-size: 14px;
                                            "
                                            >Upload your Image</span
                                          >
                                          <input
                                            v-show="false"
                                            accept="image/*"
                                            id="file"
                                            ref="files"
                                            type="file"
                                            @change="uploadImagesSlider"
                                          />
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogSlider = false"
                          >
                            Close
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="uploadAllImagesSlider()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs12 sm6 md3 pa-2 v-for="(data, d) in slider" :key="d">
                  <v-layout wrap justify-start>
                    <v-flex>
                      <v-img :src="mediaURL + data.image"
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyTrainingDetails">
              <CourseOffering @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyTrainingDetails">
              <Curriculum @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyTrainingDetails">
              <AuditingStrategy @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyTrainingDetails">
              <AdaptiveTraining @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap justify-center v-if="user.bgfSafetyTrainingDetails">
              <WhyChoose @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyTrainingDetails">
              <v-flex xs12 text-right py-5>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  persistent
                  max-width="600px"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark color="green" v-bind="attrs" v-on="on">
                      Update</v-btn
                    >
                  </template>
                  <v-card>
                    <v-form>
                      <v-card-title>
                        <span class="headline">Updates Details</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                outlined
                                dense
                                v-model="
                                  user.bgfSafetyTrainingDetails.bottomContent
                                    .title
                                "
                                label="Title"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                outlined
                                dense
                                v-model="
                                  user.bgfSafetyTrainingDetails.bottomContent
                                    .description
                                "
                                label="Description"
                                required
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                outlined
                                dense
                                v-model="
                                  user.bgfSafetyTrainingDetails.bottomContent
                                    .smallDescription
                                "
                                label="Small Description"
                                required
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                              <ImageComp
                                :singleImage="
                                  user.bgfSafetyTrainingDetails.bottomContent
                                    .image
                                "
                                @stepper="winStepper"
                                :heading="'Upload Bottom Image'"
                                :componentType="'bottomImage'"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="update()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 md8 pa-2 align-self-center>
                <span style="font-family: poppinssemibold; font-size: 16px">
                  {{ user.bgfSafetyTrainingDetails.bottomContent.title }}
                  <br />
                  <br />
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 15px;
                      text-align: justify;
                    "
                  >
                    {{
                      user.bgfSafetyTrainingDetails.bottomContent.description
                    }}</span
                  >
                  <br />
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      user.bgfSafetyTrainingDetails.bottomContent
                        .smallDescription
                    }}</span
                  >
                </span>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-img
                  :src="
                    mediaURL + user.bgfSafetyTrainingDetails.bottomContent.image
                  "
                  ><template v-slot:placeholder> <ImageLoader /> </template
                ></v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyConsultingDetails">
              <SafetyConsultingTop @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyConsultingDetails">
              <SafetyConsultingExpert @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyConsultingDetails">
              <SafetyConsultingHazop @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyConsultingDetails">
              <SafetyConsultingComp @stepper="winStepper" :storage="user" />
            </v-layout>
            <v-layout wrap v-if="user.bgfSafetyConsultingDetails">
              <SafetyConsultingBottom @stepper="winStepper" :storage="user" />
            </v-layout>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import CourseOffering from "./courseOffering";
import Curriculum from "./curriculum";
import AuditingStrategy from "./auditingStrategy";
import AdaptiveTraining from "./adaptiveTraining";
import WhyChoose from "./whyChoose";
import SafetyConsultingTop from "./safetyConsultingTop";
import SafetyConsultingExpert from "./safetyConsultingExpert";
import SafetyConsultingHazop from "./safetyConsultingHazop";
import SafetyConsultingComp from "./safetyConsultingComp";
import SafetyConsultingBottom from "./safetyConsultingBottom";
import ImageComp from "@/components/Common/singleImages";
import ImageCropper from "../../../components/Common/imageCropper";
export default {
  components: {
    VueEditor,
    Curriculum,
    CourseOffering,
    AuditingStrategy,
    AdaptiveTraining,
    WhyChoose,
    SafetyConsultingTop,
    SafetyConsultingExpert,
    SafetyConsultingHazop,
    SafetyConsultingComp,
    SafetyConsultingBottom,
    ImageComp,
    ImageCropper,
  },
  props: ["subid"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      dialogGeneral: false,
      dialogSlider: false,
      itemid: "",
      category: [],
      categoryView: "",
      generalImage: "",
      bottomImage: "",
      imageArray: [],
      imageArraySlider: [],
      Images: new FormData(),
      ImagesSlider: new FormData(),
      formData: new FormData(),
      formDataSlider: new FormData(),
      selectedFiles: null,
      selectedFilesSlider: null,
      currentImage: "",
      cropImageDialog: false,
      cropImageDialogSlider: false,
      image: null,
      imageSlider: null,
      itemediting: [],
      user: [],
      data: [],
      name: [],
      slider: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() {
    this.getData();
    this.getNewSlider();
  },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
      this.cropImageDialogSlider = payload.dialog;
      if (payload.image) {
        this.collectImagesSlider(payload.image);
      }
    },
    winStepper(window_data) {
      if (window_data.type == "updates") {
        if (window_data.getData) this.getData();
      }
      if (window_data.type == "generalImage") {
        this.generalImage = window_data.selectedFiles;
      }
      if (window_data.type == "bottomImage") {
        this.bottomImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getNewSlider() {
      this.appLoading = true;
      axios({
        url: "/category/slider/list/admin",
        method: "POST",
      })
        .then((response) => {
          this.appLoading = false;
          this.slider = response.data.data;
          this.pages = response.data.pages;
          this.totalPage = Math.ceil(response.data.totalLength / this.limit);
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["bottomContent"] = this.user.bgfSafetyTrainingDetails.bottomContent;
      axios({
        url: "/course/category/bottomContent/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.bottomImage) {
              this.uploadBottomImage();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editGeneral() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["details"] = this.user.generalDetails;
      axios({
        url: "/course/category/edit/generalDetails",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.generalImage) {
              this.uploadGeneralImage();
            }
            if (this.imageArray.length > 0) {
              this.uploadAllImages();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialogGeneral = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadGeneralImage() {
      let formData = new FormData();
      formData.append("id", this.$route.query.id);
      formData.append("photo", this.generalImage);
      axios({
        method: "POST",
        url: "/category/upload/generalDetails/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadBottomImage() {
      let formData = new FormData();
      formData.append("id", this.$route.query.id);
      formData.append("photo", this.bottomImage);
      axios({
        method: "POST",
        url: "/category/upload/bgfSafetyTrainingDetails/bottomContent/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    removePhoto(i) {
      var Data = {};
      this.user.generalDetails.silderImages.splice(i, 1);
      Data["position"] = i + 1;
      Data["id"] = this.$route.query.id;
      axios({
        method: "POST",
        url: "/category/generalContent/RemoveSliderImagesingle",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArraySlider(i) {
      var values = this.formDataSlider.getAll("photos");
      values.splice(i, 1);
      this.formDataSlider.set("photos", values);
      this.imageArraySlider.splice(i, 1);
    },
    removePhotoSlider(i, id) {
      var Data = {};
      this.slider.splice(i, 1);
      // Data["position"] = i + 1;
      Data["id"] = id._id;
      axios({
        method: "POST",
        url: "/slider/delete",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImagesSlider(event) {
      if (this.imageArraySlider.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialogSlider = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("sliderImages", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    collectImagesSlider(image) {
      this.selectedFilesSlider = image;
      this.ImagesSlider.append("image", this.selectedFilesSlider);
      this.formDataSlider.append("photos", this.selectedFilesSlider);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArraySlider.push(urls);
        if (this.imageArraySlider) {
          this.imageSlider = this.imageArraySlider[0];
        }
      };
    },
    uploadAllImages() {
      this.appLoading = true;
      this.formData.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/category/upload/generalContent/slider/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            location.reload();
            this.getData();
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadAllImagesSlider() {
      this.appLoading = true;
      this.formDataSlider.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/slider/UploadImageAll",
        data: this.formDataSlider,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            location.reload();
            this.getNewSlider();
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>
  