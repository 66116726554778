<template>
    <div>
        <ServerError v-if="ServerError" />
        <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
        <v-snackbar v-model="showsnackbar" color="black" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showsnackbar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap v-if="storage.bgfSafetyTrainingDetails">
            <v-flex xs12 text-right pt-5>
                <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="800px" :key="dialog">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small dark color="green" v-bind="attrs" v-on="on"> Update </v-btn>
                    </template>
                    <v-card>
                        <v-form>
                            <v-card-title>
                                <span class="headline">Updates Details</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.title"
                                                label="Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp :singleImage="storage.bgfSafetyTrainingDetails.adaptiveTraining.image
                                                " @stepper="winStepper" :heading="'Upload Adaptive Training Image'"
                                                :componentType="'adaptiveImage'" />
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint.title"
                                                label="First Point Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint.description"
                                                label="First Point Description" required></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.secondPoint.title"
                                                label="Second Point Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.secondPoint.description"
                                                label="Second Point Description" required></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.thirdPoint.title"
                                                label="Third Point Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.thirdPoint.description"
                                                label="Third Point Description" required></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.fourthPoint.title"
                                                label="Fourth Point Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.adaptiveTraining.fourthPoint.description"
                                                label="Fourth Point Description" required></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    Close
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="update()"> Save </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs12>
                <span style="font-family: poppinsbold; font-size: 18px;">
                    {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.title }}
                </span>
            </v-flex>
            <v-layout wrap>
                <v-flex xs6 sm6 md4>
                    <v-img :src="mediaURL + storage.bgfSafetyTrainingDetails.adaptiveTraining.image"><template v-slot:placeholder>
            <ImageLoader />
          </template></v-img>
                </v-flex>
                <v-flex xs6 sm6 md8 align-self-center>
                    <v-layout wrap v-if="storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint">
                        <v-flex xs2 sm1 md1 pa-2>
                            <v-icon>mdi-account</v-icon>
                        </v-flex>
                        <v-flex xs10 sm11 md11 pa-2 text-left>
                            <span style="font-family: poppinsbold; font-size: 16px;">
                                {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint.title }}
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px; text-align: justify;"> {{
                                    storage.bgfSafetyTrainingDetails.adaptiveTraining.firstPoint.description }}</span>
                            </span>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="storage.bgfSafetyTrainingDetails.adaptiveTraining.secondPoint">
                        <v-flex xs2 sm1 md1 pa-2>
                            <v-icon>mdi-account</v-icon>
                        </v-flex>
                        <v-flex xs10 sm11 md11 pa-2 text-left>
                            <span style="font-family: poppinsbold; font-size: 16px;">
                                {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.secondPoint.title }}
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px; text-align: justify;"> {{
                                    storage.bgfSafetyTrainingDetails.adaptiveTraining.secondPoint.description }}</span>
                            </span>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="storage.bgfSafetyTrainingDetails.adaptiveTraining.thirdPoint">
                        <v-flex xs2 sm1 md1 pa-2>
                            <v-icon>mdi-account</v-icon>
                        </v-flex>
                        <v-flex xs10 sm11 md11 pa-2 text-left>
                            <span style="font-family: poppinsbold; font-size: 16px;">
                                {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.thirdPoint.title }}
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px; text-align: justify;"> {{
                                    storage.bgfSafetyTrainingDetails.adaptiveTraining.thirdPoint.description }}</span>
                            </span>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="storage.bgfSafetyTrainingDetails.adaptiveTraining.fourthPoint">
                        <v-flex xs2 sm1 md1 pa-2>
                            <v-icon>mdi-account</v-icon>
                        </v-flex>
                        <v-flex xs10 sm11 md11 pa-2 text-left>
                            <span style="font-family: poppinsbold; font-size: 16px;">
                                {{ storage.bgfSafetyTrainingDetails.adaptiveTraining.fourthPoint.title }}
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px; text-align: justify;"> {{
                                    storage.bgfSafetyTrainingDetails.adaptiveTraining.fourthPoint.description }}</span>
                            </span>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/Common/singleImages";
export default {
    components: {
        ImageComp,
    },
    props: ["storage"],
    data() {
        return {
            showsnackbar: false,
            ServerError: false,
            msg: null,
            appLoading: false,
            dialog: false,
            itemid: "",
            category: [],
            categoryView: "",
            adaptiveImage: "",
            itemediting: [],
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
            },
        };
    },
    mounted() {
    },
    methods: {
        winStepper(window_data) {
            if (window_data.type == "adaptiveImage") {
                this.adaptiveImage = window_data.selectedFiles;
            }
        },
        update() {
            var data = {};
            data["id"] = this.$route.query.id;
            data["adaptiveTraining"] = this.storage.bgfSafetyTrainingDetails.adaptiveTraining;
            axios({
                url: "/course/category/adaptiveTrainingContent/edit",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.editdialog = false;

                    this.appLoading = false;
                    if (response.data.status) {
                        if (this.adaptiveImage) {
                            this.uploadAdaptiveImage();
                        }
                        this.msg = "Edited Sucessfully";
                        this.showsnackbar = true;
                        this.$emit("stepper", {
                            type: "updates",
                            getData: true,
                        });
                        this.dialog = false
                        // this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadAdaptiveImage() {
            let formData = new FormData();
            formData.append("photo", this.adaptiveImage);
            formData.append("id", this.$route.query.id);
            axios({
                method: "POST",
                url: "/category/upload/bgfSafetyTrainingDetails/adaptiveTraining/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.showsnackbar = true;
                        this.msg = "Uploaded Successfully";
                        this.$emit("stepper", {
                            type: "updates",
                            getData: true,
                        });
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
<style scoped>
.item_title {
    font-weight: bold;
    font-family: poppinssemibold;
}
</style>
