<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="storage.bgfSafetyTrainingDetails">
      <v-flex xs12 text-right py-5>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="600px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="green" v-bind="attrs" v-on="on">
              Update
            </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Updates Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        dense
                        v-model="
                          storage.bgfSafetyTrainingDetails.middleContent.title
                        "
                        label="Title"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor
                          v-model="
                            storage.bgfSafetyTrainingDetails.middleContent
                              .description
                          "
                        ></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <ImageComp
                        :singleImage="
                          storage.bgfSafetyTrainingDetails.middleContent.image
                        "
                        @stepper="winStepper"
                        :heading="'Upload Middle Image'"
                        :componentType="'middleImage'"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="update()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs12 sm6 md8 pa-2 align-self-center>
        <span style="font-family: poppinsbold; font-size: 16px">
          {{ storage.bgfSafetyTrainingDetails.middleContent.title }}
          <br />
          <br />
          <span
            style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            "
            v-html="storage.bgfSafetyTrainingDetails.middleContent.description"
          ></span>
        </span>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-img :src="mediaURL + storage.bgfSafetyTrainingDetails.middleContent.image"><template v-slot:placeholder>
            <ImageLoader />
          </template></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
export default {
  components: {
    VueEditor,
    ImageComp,
  },
  props: ["storage"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      middleImage: "",
      itemediting: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "middleImage") {
        this.middleImage = window_data.selectedFiles;
      }
    },
    update() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["middleContent"] =
        this.storage.bgfSafetyTrainingDetails.middleContent;
      axios({
        url: "/course/category/middleContent/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.middleImage) {
              this.uploadMiddleImage();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.$emit("stepper", {
              type: "updates",
              getData: true,
            });
            this.dialog = false;
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadMiddleImage() {
      let formData = new FormData();
      formData.append("photo", this.middleImage);
      formData.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/category/upload/bgfSafetyTrainingDetails/middleContent/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$emit("stepper", {
              type: "updates",
              getData: true,
            });
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>
