var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":"black","right":""},model:{value:(_vm.showsnackbar),callback:function ($$v) {_vm.showsnackbar=$$v},expression:"showsnackbar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showsnackbar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.storage.bgfSafetyTrainingDetails)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-5":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"18px"}},[_vm._v(" Why Choose Us ")]),_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","text-right":"","pt-5":""}},[_c('v-dialog',{key:_vm.dialog,attrs:{"retain-focus":false,"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"green"}},'v-btn',attrs,false),on),[_vm._v(" Update ")])]}}],null,false,1455480205),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Updates Details")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"First Point Title","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            .title
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            , "title", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.first\n                            .title\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"First Point Description","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.first\n                            .description\n                        "}})],1),_c('v-flex',{attrs:{"py-5":"","xs12":"","sm12":"","px-4":""}},[_c('v-layout',{staticStyle:{"border-style":"dotted","border-color":"black","background-color":"white"},attrs:{"wrap":"","justify-center":"","pa-2":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .first.icon && !_vm.imagePreview1
                            )?_c('v-img',{staticStyle:{},attrs:{"src":_vm.mediaURL +
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .first.icon,"contain":"","height":"200px"}}):_vm._e(),(_vm.imagePreview1)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview1),expression:"showPreview1"}],staticClass:"align-center",attrs:{"src":_vm.imagePreview1,"contain":"","height":"200px"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-icon',{staticStyle:{"font-family":"poppinssemibold","font-size":"17px"},attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.$refs.showImage1.click()}}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"15px"}},[_vm._v("Upload First Image")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"showImage1",attrs:{"id":"file1","multiple":"","type":"file"},on:{"change":_vm.showImage1}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Second Point Title","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            .title
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            , "title", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.second\n                            .title\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Second Point Description","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.second\n                            .description\n                        "}})],1),_c('v-flex',{attrs:{"py-5":"","xs12":"","sm12":"","px-4":""}},[_c('v-layout',{staticStyle:{"border-style":"dotted","border-color":"black","background-color":"white"},attrs:{"wrap":"","justify-center":"","pa-2":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .second.icon && !_vm.imagePreview2
                            )?_c('v-img',{staticStyle:{},attrs:{"src":_vm.mediaURL +
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .second.icon,"contain":"","height":"200px"}}):_vm._e(),(_vm.imagePreview2)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview2),expression:"showPreview2"}],staticClass:"align-center",attrs:{"src":_vm.imagePreview2,"contain":"","height":"200px"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-icon',{staticStyle:{"font-family":"poppinssemibold","font-size":"17px"},attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.$refs.showImage2.click()}}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"15px"}},[_vm._v("Upload Second Image")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"showImage2",attrs:{"id":"file2","multiple":"","type":"file"},on:{"change":_vm.showImage2}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Third Point Title","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            .title
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            , "title", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.third\n                            .title\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Third Point Description","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.third\n                            .description\n                        "}})],1),_c('v-flex',{attrs:{"py-5":"","xs12":"","sm12":"","px-4":""}},[_c('v-layout',{staticStyle:{"border-style":"dotted","border-color":"black","background-color":"white"},attrs:{"wrap":"","justify-center":"","pa-2":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .third.icon && !_vm.imagePreview3
                            )?_c('v-img',{staticStyle:{},attrs:{"src":_vm.mediaURL +
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .third.icon,"contain":"","height":"200px"}}):_vm._e(),(_vm.imagePreview3)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview3),expression:"showPreview3"}],staticClass:"align-center",attrs:{"src":_vm.imagePreview3,"contain":"","height":"200px"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-icon',{staticStyle:{"font-family":"poppinssemibold","font-size":"17px"},attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.$refs.showImage3.click()}}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"15px"}},[_vm._v("Upload Third Image")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"showImage3",attrs:{"id":"file3","multiple":"","type":"file"},on:{"change":_vm.showImage3}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Fourth Point Title","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            .title
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            , "title", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.fourth\n                            .title\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Fourth Point Description","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.fourth\n                            .description\n                        "}})],1),_c('v-flex',{attrs:{"py-5":"","xs12":"","sm12":"","px-4":""}},[_c('v-layout',{staticStyle:{"border-style":"dotted","border-color":"black","background-color":"white"},attrs:{"wrap":"","justify-center":"","pa-2":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fourth.icon && !_vm.imagePreview4
                            )?_c('v-img',{staticStyle:{},attrs:{"src":_vm.mediaURL +
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fourth.icon,"contain":"","height":"200px"}}):_vm._e(),(_vm.imagePreview4)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview4),expression:"showPreview4"}],staticClass:"align-center",attrs:{"src":_vm.imagePreview4,"contain":"","height":"200px"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-icon',{staticStyle:{"font-family":"poppinssemibold","font-size":"17px"},attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.$refs.showImage4.click()}}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"15px"}},[_vm._v("Upload Fourth Image")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"showImage4",attrs:{"id":"file4","multiple":"","type":"file"},on:{"change":_vm.showImage4}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Fifth Point Title","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            .title
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            , "title", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.fifth\n                            .title\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Fifth Point Description","required":""},model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.whyChooseUs.fifth\n                            .description\n                        "}})],1),_c('v-flex',{attrs:{"py-5":"","xs12":"","sm12":"","px-4":""}},[_c('v-layout',{staticStyle:{"border-style":"dotted","border-color":"black","background-color":"white"},attrs:{"wrap":"","justify-center":"","pa-2":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[(
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fifth.icon && !_vm.imagePreview5
                            )?_c('v-img',{staticStyle:{},attrs:{"src":_vm.mediaURL +
                              _vm.storage.bgfSafetyTrainingDetails.whyChooseUs
                                .fifth.icon,"contain":"","height":"200px"}}):_vm._e(),(_vm.imagePreview5)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview5),expression:"showPreview5"}],staticClass:"align-center",attrs:{"src":_vm.imagePreview5,"contain":"","height":"200px"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-icon',{staticStyle:{"font-family":"poppinssemibold","font-size":"17px"},attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.$refs.showImage5.click()}}},[_vm._v("mdi-plus")]),_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"15px"}},[_vm._v("Upload Fifth Image")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"showImage5",attrs:{"id":"file5","multiple":"","type":"file"},on:{"change":_vm.showImage5}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","pt-5":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","py-5":""}},[_c('v-img',{attrs:{"contain":"","height":"200px","src":_vm.mediaURL +
                  _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first.icon},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first.title)+" "),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.first .description))])])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","py-5":""}},[_c('v-img',{attrs:{"contain":"","height":"200px","src":_vm.mediaURL +
                  _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second.icon},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second.title)+" "),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.second .description))])])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","py-5":""}},[_c('v-img',{attrs:{"contain":"","height":"200px","src":_vm.mediaURL +
                  _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third.icon},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third.title)+" "),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.third .description))])])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","py-5":""}},[_c('v-img',{attrs:{"contain":"","height":"200px","src":_vm.mediaURL +
                  _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth.icon},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth.title)+" "),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fourth .description))])])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pa-2":""}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","py-5":""}},[_c('v-img',{attrs:{"contain":"","height":"200px","src":_vm.mediaURL +
                  _vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth.icon}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth.title)+" "),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.whyChooseUs.fifth .description))])])])],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }