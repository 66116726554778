<template>
    <div>
        <ServerError v-if="ServerError" />
        <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
        <v-snackbar v-model="showsnackbar" color="black" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showsnackbar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap v-if="storage.bgfSafetyTrainingDetails">
            <v-flex xs6>
                <span style="font-family: poppinsbold; font-size: 18px;">
                    Course Offerings
                </span>
            </v-flex>
            <v-flex xs6 text-right>
                <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="600px" :key="dialog">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small dark color="green" v-bind="attrs" v-on="on"> Update </v-btn>
                    </template>
                    <v-card>
                        <v-form>
                            <v-card-title>
                                <span class="headline">Updates Details</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingFirst.title"
                                                label="Course Offering First Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingFirst.description"
                                                label="Course Offering First Description" required></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingSecond.title"
                                                label="Course Offering Second Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingSecond.description"
                                                label="Course Offering Second Description" required></v-textarea>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingThird.title"
                                                label="Course Offering Third Title" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-textarea outlined dense
                                                v-model="storage.bgfSafetyTrainingDetails.courseOfferingThird.description"
                                                label="Course Offering Third Description" required></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    Close
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="update()"> Save </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-layout wrap pt-5>
                <v-flex xs12 sm6 v-if="storage.bgfSafetyTrainingDetails.courseOfferingFirst" pa-2>
                    <v-card height="180px">
                        <v-layout wrap pa-5>
                            <v-flex xs2>
                                <v-icon>mdi-account</v-icon>
                            </v-flex>
                            <v-flex xs10>
                                <span style="font-family: poppinsmedium; font-size: 15px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingFirst.title }}</span>
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingFirst.description }}</span>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6 v-if="storage.bgfSafetyTrainingDetails.courseOfferingSecond" pa-2>
                    <v-card height="180px">
                        <v-layout wrap pa-5>
                            <v-flex xs2>
                                <v-icon>mdi-account</v-icon>
                            </v-flex>
                            <v-flex xs10>
                                <span style="font-family: poppinsmedium; font-size: 15px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingSecond.title }}</span>
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingSecond.description }}</span>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6 v-if="storage.bgfSafetyTrainingDetails.courseOfferingThird" pa-2>
                    <v-card height="180px">
                        <v-layout wrap pa-5>
                            <v-flex xs2>
                                <v-icon>mdi-account</v-icon>
                            </v-flex>
                            <v-flex xs10>
                                <span style="font-family: poppinsmedium; font-size: 15px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingThird.title }}</span>
                                <br>
                                <span style="font-family: poppinsregular; font-size: 14px;">{{
                                    storage.bgfSafetyTrainingDetails.courseOfferingThird.description }}</span>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: ["storage"],
    data() {
        return {
            showsnackbar: false,
            ServerError: false,
            msg: null,
            appLoading: false,
            dialog: false,
            itemid: "",
            category: [],
            categoryView: "",
            itemediting: [],
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
            },
        };
    },
    mounted() {
    },
    methods: {
        update() {
            var data = {};
            data["id"] = this.$route.query.id;
            data["courseOfferingFirst"] = this.storage.bgfSafetyTrainingDetails.courseOfferingFirst;
            data["courseOfferingSecond"] = this.storage.bgfSafetyTrainingDetails.courseOfferingSecond;
            data["courseOfferingThird"] = this.storage.bgfSafetyTrainingDetails.courseOfferingThird;
            axios({
                url: "/course/category/courseOffering/edit",
                method: "POST",
                data: data,
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.editdialog = false;

                    this.appLoading = false;
                    if (response.data.status) {
                        //   if (this.categoryImg) {
                        //     this.uploadattachments1();
                        //   }
                        this.msg = "Edited Sucessfully";
                        this.showsnackbar = true;
                        this.$emit("stepper", {
                            type: "updates",
                            getData: true,
                        });
                        this.dialog = false
                        // this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

    },
};
</script>
<style scoped>
.item_title {
    font-weight: bold;
    font-family: poppinssemibold;
}
</style>
  