var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"spinner":"bar-fade-scale","color":"black","size":"128","is-full-screen":""}}),_c('v-snackbar',{attrs:{"color":"black","right":""},model:{value:(_vm.showsnackbar),callback:function ($$v) {_vm.showsnackbar=$$v},expression:"showsnackbar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showsnackbar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.storage.bgfSafetyTrainingDetails.topContent.title)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","text-right":"","pt-5":""}},[_c('v-dialog',{key:_vm.dialog,attrs:{"retain-focus":false,"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","color":"green"}},'v-btn',attrs,false),on),[_vm._v(" Update ")])]}}],null,false,1455480205),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Updates Details")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Title","required":""},model:{value:(
                        _vm.storage.bgfSafetyTrainingDetails.topContent.title
                      ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.topContent, "title", $$v)},expression:"\n                        storage.bgfSafetyTrainingDetails.topContent.title\n                      "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Description")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"app"}},[_c('vue-editor',{model:{value:(
                          _vm.storage.bgfSafetyTrainingDetails.topContent
                            .description
                        ),callback:function ($$v) {_vm.$set(_vm.storage.bgfSafetyTrainingDetails.topContent
                            , "description", $$v)},expression:"\n                          storage.bgfSafetyTrainingDetails.topContent\n                            .description\n                        "}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('ImageComp',{attrs:{"singleImage":_vm.storage.bgfSafetyTrainingDetails.topContent.image,"heading":'Upload Top Image',"componentType":'topImage'},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1),(_vm.storage.bgfSafetyTrainingDetails.topContent.title)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pt-5":""}},[_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.storage.bgfSafetyTrainingDetails.topContent.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md8":"","pa-2":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.storage.bgfSafetyTrainingDetails.topContent.title)+" "),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"14px","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.storage.bgfSafetyTrainingDetails.topContent.description)}})])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }