<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center v-if="storage.bgfSafetyTrainingDetails" pt-5>
      <v-flex xs12 text-center>
        <span style="font-family: poppinsbold; font-size: 18px">
          Why Choose Us
        </span>
        <v-spacer> </v-spacer>
        <v-flex xs12 text-right pt-5>
          <v-dialog
            :retain-focus="false"
            v-model="dialog"
            persistent
            max-width="800px"
            :key="dialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small dark color="green" v-bind="attrs" v-on="on">
                Update
              </v-btn>
            </template>
            <v-card>
              <v-form>
                <v-card-title>
                  <span class="headline">Updates Details</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.first
                              .title
                          "
                          label="First Point Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.first
                              .description
                          "
                          label="First Point Description"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-flex py-5 xs12 sm12 px-4>
                        <v-layout
                          wrap
                          justify-center
                          pa-2
                          py-5
                          style="
                            border-style: dotted;
                            border-color: black;
                            background-color: white;
                          "
                        >
                          <v-flex xs12>
                            <v-img
                              v-if="
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .first.icon && !imagePreview1
                              "
                              :src="
                                mediaURL +
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .first.icon
                              "
                              style="align-center"
                              contain
                              height="200px"
                            />
                            <img
                              v-if="imagePreview1"
                              :src="imagePreview1"
                              v-show="showPreview1"
                              class="align-center"
                              contain
                              height="200px"
                            />
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <v-icon
                              small
                              @click="$refs.showImage1.click()"
                              color="black"
                              style="
                                font-family: poppinssemibold;
                                font-size: 17px;
                              "
                              >mdi-plus</v-icon
                            >
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                              "
                              >Upload First Image</span
                            >
                            <input
                              v-show="false"
                              id="file1"
                              ref="showImage1"
                              multiple
                              type="file"
                              @change="showImage1"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.second
                              .title
                          "
                          label="Second Point Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.second
                              .description
                          "
                          label="Second Point Description"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-flex py-5 xs12 sm12 px-4>
                        <v-layout
                          wrap
                          justify-center
                          pa-2
                          py-5
                          style="
                            border-style: dotted;
                            border-color: black;
                            background-color: white;
                          "
                        >
                          <v-flex xs12>
                            <v-img
                              v-if="
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .second.icon && !imagePreview2
                              "
                              :src="
                                mediaURL +
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .second.icon
                              "
                              style="align-center"
                              contain
                              height="200px"
                            />
                            <img
                              v-if="imagePreview2"
                              :src="imagePreview2"
                              v-show="showPreview2"
                              class="align-center"
                              contain
                              height="200px"
                            />
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <v-icon
                              small
                              @click="$refs.showImage2.click()"
                              color="black"
                              style="
                                font-family: poppinssemibold;
                                font-size: 17px;
                              "
                              >mdi-plus</v-icon
                            >
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                              "
                              >Upload Second Image</span
                            >
                            <input
                              v-show="false"
                              id="file2"
                              ref="showImage2"
                              multiple
                              type="file"
                              @change="showImage2"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.third
                              .title
                          "
                          label="Third Point Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.third
                              .description
                          "
                          label="Third Point Description"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-flex py-5 xs12 sm12 px-4>
                        <v-layout
                          wrap
                          justify-center
                          pa-2
                          py-5
                          style="
                            border-style: dotted;
                            border-color: black;
                            background-color: white;
                          "
                        >
                          <v-flex xs12>
                            <v-img
                              v-if="
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .third.icon && !imagePreview3
                              "
                              :src="
                                mediaURL +
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .third.icon
                              "
                              style="align-center"
                              contain
                              height="200px"
                            />
                            <img
                              v-if="imagePreview3"
                              :src="imagePreview3"
                              v-show="showPreview3"
                              class="align-center"
                              contain
                              height="200px"
                            />
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <v-icon
                              small
                              @click="$refs.showImage3.click()"
                              color="black"
                              style="
                                font-family: poppinssemibold;
                                font-size: 17px;
                              "
                              >mdi-plus</v-icon
                            >
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                              "
                              >Upload Third Image</span
                            >
                            <input
                              v-show="false"
                              id="file3"
                              ref="showImage3"
                              multiple
                              type="file"
                              @change="showImage3"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                              .title
                          "
                          label="Fourth Point Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                              .description
                          "
                          label="Fourth Point Description"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-flex py-5 xs12 sm12 px-4>
                        <v-layout
                          wrap
                          justify-center
                          pa-2
                          py-5
                          style="
                            border-style: dotted;
                            border-color: black;
                            background-color: white;
                          "
                        >
                          <v-flex xs12>
                            <v-img
                              v-if="
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .fourth.icon && !imagePreview4
                              "
                              :src="
                                mediaURL +
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .fourth.icon
                              "
                              style="align-center"
                              contain
                              height="200px"
                            />
                            <img
                              v-if="imagePreview4"
                              :src="imagePreview4"
                              v-show="showPreview4"
                              class="align-center"
                              contain
                              height="200px"
                            />
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <v-icon
                              small
                              @click="$refs.showImage4.click()"
                              color="black"
                              style="
                                font-family: poppinssemibold;
                                font-size: 17px;
                              "
                              >mdi-plus</v-icon
                            >
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                              "
                              >Upload Fourth Image</span
                            >
                            <input
                              v-show="false"
                              id="file4"
                              ref="showImage4"
                              multiple
                              type="file"
                              @change="showImage4"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                              .title
                          "
                          label="Fifth Point Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="
                            storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                              .description
                          "
                          label="Fifth Point Description"
                          required
                        ></v-textarea>
                      </v-col>
                      <v-flex py-5 xs12 sm12 px-4>
                        <v-layout
                          wrap
                          justify-center
                          pa-2
                          py-5
                          style="
                            border-style: dotted;
                            border-color: black;
                            background-color: white;
                          "
                        >
                          <v-flex xs12>
                            <v-img
                              v-if="
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .fifth.icon && !imagePreview5
                              "
                              :src="
                                mediaURL +
                                storage.bgfSafetyTrainingDetails.whyChooseUs
                                  .fifth.icon
                              "
                              style="align-center"
                              contain
                              height="200px"
                            />
                            <img
                              v-if="imagePreview5"
                              :src="imagePreview5"
                              v-show="showPreview5"
                              class="align-center"
                              contain
                              height="200px"
                            />
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <v-icon
                              small
                              @click="$refs.showImage5.click()"
                              color="black"
                              style="
                                font-family: poppinssemibold;
                                font-size: 17px;
                              "
                              >mdi-plus</v-icon
                            >
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                              "
                              >Upload Fifth Image</span
                            >
                            <input
                              v-show="false"
                              id="file5"
                              ref="showImage5"
                              multiple
                              type="file"
                              @change="showImage5"
                            />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="update()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-flex>
      <v-layout wrap pt-5>
        <v-flex xs12 sm6 md4 pa-2>
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-center py-5>
                <!-- <v-avatar color="indigo" size="100"> </v-avatar> -->
                <v-img
                  contain
                  height="200px"
                  :src="
                    mediaURL +
                    storage.bgfSafetyTrainingDetails.whyChooseUs.first.icon
                  "
                  ><template v-slot:placeholder>
                    <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{ storage.bgfSafetyTrainingDetails.whyChooseUs.first.title }}
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      storage.bgfSafetyTrainingDetails.whyChooseUs.first
                        .description
                    }}</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 pa-2>
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-center py-5>
                <v-img
                  contain
                  height="200px"
                  :src="
                    mediaURL +
                    storage.bgfSafetyTrainingDetails.whyChooseUs.second.icon
                  "
                  ><template v-slot:placeholder>
                    <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{
                    storage.bgfSafetyTrainingDetails.whyChooseUs.second.title
                  }}
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      storage.bgfSafetyTrainingDetails.whyChooseUs.second
                        .description
                    }}</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 pa-2>
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-center py-5>
                <v-img
                  contain
                  height="200px"
                  :src="
                    mediaURL +
                    storage.bgfSafetyTrainingDetails.whyChooseUs.third.icon
                  "
                  ><template v-slot:placeholder>
                    <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{ storage.bgfSafetyTrainingDetails.whyChooseUs.third.title }}
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      storage.bgfSafetyTrainingDetails.whyChooseUs.third
                        .description
                    }}</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 pa-2>
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-center py-5>
                <v-img
                  contain
                  height="200px"
                  :src="
                    mediaURL +
                    storage.bgfSafetyTrainingDetails.whyChooseUs.fourth.icon
                  "
                  ><template v-slot:placeholder>
                    <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{
                    storage.bgfSafetyTrainingDetails.whyChooseUs.fourth.title
                  }}
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      storage.bgfSafetyTrainingDetails.whyChooseUs.fourth
                        .description
                    }}</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4 pa-2>
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12 text-center py-5>
                <v-img
                  contain
                  height="200px"
                  :src="
                    mediaURL +
                    storage.bgfSafetyTrainingDetails.whyChooseUs.fifth.icon
                  "
                ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-family: poppinsbold; font-size: 16px">
                  {{ storage.bgfSafetyTrainingDetails.whyChooseUs.fifth.title }}
                  <br />
                  <span
                    style="
                      font-family: poppinsregular;
                      font-size: 14px;
                      text-align: justify;
                    "
                  >
                    {{
                      storage.bgfSafetyTrainingDetails.whyChooseUs.fifth
                        .description
                    }}</span
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      imagePreview1: "",
      showPreview1: false,
      imagePreview2: "",
      showPreview2: false,
      imagePreview3: "",
      showPreview3: false,
      imagePreview4: "",
      showPreview4: false,
      imagePreview5: "",
      showPreview5: false,
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      file5: "",

      itemediting: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() {},
  methods: {
    update() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["whyChooseUs"] = this.storage.bgfSafetyTrainingDetails.whyChooseUs;
      axios({
        url: "/course/category/whyChooseUsContent/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (
              this.file1 ||
              this.file2 ||
              this.file3 ||
              this.file4 ||
              this.file5
            ) {
              this.uploadImage();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.$emit("stepper", {
              type: "updates",
              getData: true,
            });
            this.dialog = false;
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage1(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview1 = e.target.result;
      };
      this.showPreview1 = true;
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    showImage2(e) {
      this.file2 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview2 = e.target.result;
      };
      this.showPreview2 = true;
      if (this.file2) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file2.name)) {
          reader.readAsDataURL(this.file2);
        }
      }
    },
    showImage3(e) {
      this.file3 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview3 = e.target.result;
      };
      this.showPreview3 = true;
      if (this.file3) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file3.name)) {
          reader.readAsDataURL(this.file3);
        }
      }
    },
    showImage4(e) {
      this.file4 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview4 = e.target.result;
      };
      this.showPreview4 = true;
      if (this.file4) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file4.name)) {
          reader.readAsDataURL(this.file4);
        }
      }
    },
    showImage5(e) {
      this.file5 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview5 = e.target.result;
      };
      this.showPreview5 = true;
      if (this.file5) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file5.name)) {
          reader.readAsDataURL(this.file5);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.$route.query.id);
      formData.append("photo", this.file1);
      formData.append("photoTwo", this.file2);
      formData.append("photoThree", this.file3);
      formData.append("photoFour", this.file4);
      formData.append("photoFive", this.file5);

      axios({
        method: "POST",
        url: "/category/upload/bgfSafetyTrainingDetails/whyChooseUs/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.file1 = "";
            this.file2 = "";
            this.file3 = "";
            this.file4 = "";
            this.file5 = "";
            formData.append = "";
            this.imagePreview1 = "";
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>