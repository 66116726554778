<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="cropImageDialog" persistent :width="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
      ? '100vw'
      : $vuetify.breakpoint.name == 'md'
        ? '80vw'
        : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      ">
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper :image="currentImage" :key="currentImage" @stepper="imageCropper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap v-if="storage.bgfSafetyConsultingDetails.topContent.title">
      <v-flex xs12 text-right py-5>
        <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="800px" :key="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="green" v-bind="attrs" v-on="on">
              Update
            </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Updates Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field outlined dense v-model="storage.bgfSafetyConsultingDetails.topContent.title
                        " label="Title" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="storage.bgfSafetyConsultingDetails.topContent
                          .content
                          "></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <span>Upload images </span>
                      <v-card class="mx-auto" outlined>
                        <v-layout wrap pa-3>
                          <v-flex xs12 md12 lg12>
                            <v-layout wrap>
                              <v-flex lg1> </v-flex>
                              <v-flex xs12>
                                <v-layout wrap justify-center fill-height>
                                  <v-flex xs12 sm6
                                    v-for="(item, i) in storage.bgfSafetyConsultingDetails.topContent.sliderImages"
                                    :key="i" pa-1>
                                    <v-img :src="mediaURL + item">
                                      <template v-slot:placeholder>
                                        <ImageLoader />
                                      </template>
                                      <v-layout wrap>
                                        <v-flex text-right pa-0>
                                          <v-avatar color="#FF3434" size="15">
                                            <v-icon color="#FFF" small @click="removePhoto(i)">
                                              mdi-close
                                            </v-icon>
                                          </v-avatar>
                                        </v-flex>
                                      </v-layout>
                                    </v-img>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 sm3 v-for="(item, i) in imageArray" :key="i" pa-1>
                                <v-img :src="item" height="50px" contain>
                                  <v-layout wrap>
                                    <v-flex text-right pa-0>
                                      <v-avatar color="#FF3434" size="15">
                                        <v-icon color="#FFF" small @click="removeImageArray(i)">
                                          mdi-close
                                        </v-icon>
                                      </v-avatar>
                                    </v-flex>
                                  </v-layout>
                                </v-img>
                              </v-flex>
                            </v-layout>

                            <div class="dottedline"></div>
                            <v-layout wrap pt-1 align-center>
                              <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                                <v-icon size="25px" id="pro_pic" @click="$refs.files.click()">mdi-plus</v-icon>
                                <span style="color: #828282; font-size: 14px">Upload your Image</span>
                                <input v-show="false" accept="image/*" id="file" ref="files" type="file"
                                  @change="uploadImages" />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="update()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs12 sm6 md12 pa-2 align-self-center>
        <span style="font-family: poppinsbold; font-size: 16px">
          {{ storage.bgfSafetyConsultingDetails.topContent.title }}
          <br />
          <br />
          <span style="
              font-family: poppinsregular;
              font-size: 14px;
              text-align: justify;
            " v-html="storage.bgfSafetyConsultingDetails.topContent.content"></span>
        </span>
      </v-flex>
      <v-flex xs12 sm12 md12 pt-16 v-if="storage.bgfSafetyConsultingDetails">
        <v-layout wrap justify-start v-if="storage.bgfSafetyConsultingDetails.topContent">
          <v-flex xs12 sm6 md3 pa-2 v-for="(item, i) in storage.bgfSafetyConsultingDetails.topContent.sliderImages"
            :key="i">
            <v-img height="200px" width="300px" contain :src="mediaURL + item"><template v-slot:placeholder>
                <ImageLoader />
              </template></v-img>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageCropper from "../../../components/Common/imageCropper";
export default {
  components: {
    VueEditor,
    ImageCropper,
  },
  props: ["storage"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      currentImage: "",
      cropImageDialog: false,
      image: null,
      itemediting: [],
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 3, nav: false },
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() { },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    update() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["topContent"] = this.storage.bgfSafetyConsultingDetails.topContent;
      axios({
        url: "/course/category/safetyConseltingDetails/topContent/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imageArray.length > 0) {
              this.uploadAllImages();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.$emit("stepper", {
              type: "updates",
              getData: true,
            });
            this.dialog = false;
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    removePhoto(i) {
      var Data = {};
      this.storage.bgfSafetyConsultingDetails.topContent.sliderImages.splice(i, 1);
      Data["position"] = i + 1;
      Data["id"] = this.$route.query.id;
      axios({
        method: "POST",
        url: "/category/bgfSafetyConsultingDetails/RemoveTopContentImagesingle",
        data: Data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.imageCount =
            //   this.product.photos.length + this.imageArray.length;
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("sliderImages", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    uploadAllImages() {
      this.appLoading = true;
      this.formData.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/category/upload/bgfSafetyConsultingDetails/topContent/image",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.$emit("stepper", {
              type: "updates",
              getData: true,
            });
            location.reload();
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>
